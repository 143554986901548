import React from "react";

export default function AboutSection() {
  return (
    <section className="about-section">
      <h1>À propos</h1>
      <div className="about-paragraphs">
        <p className="about-text">
          J'ai toujours été attiré par l'informatique. C'est pour moi un outil
          de création quasiment sans limite.
        </p>
        <p className="about-text">
          C'est pourquoi, après un master en UX (expérience utilisateur), j'ai
          décidé de me lancer dans le développement web afin de pouvoir créer
          moi-même des
          <strong>
            {" "}
            sites web, applications mobiles, mini-jeux ou programmes{" "}
          </strong>
          avec Javascript.
        </p>
        <p className="about-text">
          En ce moment, je me forme à <strong>React-native </strong>pour créer
          des <strong>applications mobiles</strong>, ainsi qu'à{" "}
          <strong>GraphQL</strong> afin de gérer les requêtes avec plus
          d'efficacité.
        </p>
      </div>
    </section>
  );
}
